import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () =>
            import('./home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: '',
        redirectTo: 'spinner',
        pathMatch: 'full',
    },
    {
        path: 'home',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'spinner',
        loadChildren: () =>
            import('./spinner/spinner.module').then((m) => m.SpinnerPageModule),
    },
    {
        path: 'spinner/:id',
        loadChildren: () =>
            import('./spinner/spinner.module').then((m) => m.SpinnerPageModule),
    },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
 
  {
    path: 'save/:id',
    loadChildren: () => import('./save/save.module').then( m => m.SavePageModule)
  },  {
    path: 'spinnerakt',
    loadChildren: () => import('./spinnerakt/spinnerakt.module').then( m => m.SpinneraktPageModule)
  },
  {
    path: 'spinnerupload',
    loadChildren: () => import('./spinnerupload/spinnerupload.module').then( m => m.SpinneruploadPageModule)
  },

  
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
